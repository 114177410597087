export const _warningSetting = {
    //筛选
    search: [
        {
            type: 'el-input',
            label: '商户名称：',
            value: 'merchantName',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
        {
            type: 'el-input',
            label: '商户标识：',
            value: 'merchantCode',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
        {
            type: 'el-select',
            label: '预警条件：',
            value: 'alertTypeList',
            multiple: true,
            props: {
                placeholder: '请选择',
                clearable: true,
            },
            options: []
        },
    ],
    //列表
    table: [
        {
            prop: 'merchantName',
            label: '商户名称'
        },
        {
            label: '预警条件',
            width: "300px",
            sortable: true,
            'sort-by': '_flagNum',
            self: {
                body: 'condition'
            }
        },
        {
            prop: 'viewSendMobile',
            label: '自动发送短信手机号'
        },
        {
            prop: 'sumSmsSendNumDay',
            label: '每日发送次数'
        },
        {
            label: '操作',
            width: '180',
            self: {
                body: 'operation'
            }
        }
    ],
    //设置模版表单
    setTmpForm: [
        {
            type: 'el-radio-group',
            value: 'type',
            childType: 'el-radio-button',
            options: [],
            change: ()=>{}
        },
        {
            label: '预警模板：',
            value: 'typeName',
        },
        {
            type: 'el-input',
            label: '预警内容：',
            value: 'smsTemplateContent',
            props: {
                disabled: true,
                type: 'textarea',
                rows: '4',
                clearable: true
            }
        },
        {
            type: 'el-input',
            label: '每日发送次数：',
            value: 'smsSendNumDay',
            props: {
                type: "number",
                clearable: true
            }
        },
        {
            type: 'el-input',
            label: '运营人员手机号：',
            value: 'operationMobile',
            props: {
                clearable: true
            }
        }
    ],
    //奖品列表
    tablePrize: [
        {
            prop: 'prizeId',
            label: '奖品id',
        },
        {
            prop: 'prizeName',
            label: '奖品名称',
        },
        {
            prop: 'residueNumber',
            label: '剩余奖品数量',
        },
        {
            label: '剩余阈值',
            self: {
                body: 'alertNumber'
            }
        }
    ],
    //手动发送短信表单
    sendSmsForm: [
        {
            type: 'el-input',
            label: '发送手机号：',
            value: 'customMobile',
            props: {
                clearable: true
            }
        },
        {
            type: 'el-select',
            label: '预警模板：',
            value: 'alertType',
            props: {
                clearable: true
            },
            options: [],
            change: ()=>{}
        },
        {
            type: 'el-input',
            label: '预警内容：',
            value: 'content',
            props: {
                disabled: true,
                type: 'textarea',
                rows: '4',
                clearable: true
            }
        }
    ],
    //规则
    rules: {
        smsTemplateContent: [{ required: true, message: '请填写预警内容', trigger: 'blur' }],
        smsSendNumDay: [{ required: true, message: '请填写每日发送次数', trigger: 'blur' }],
        operationMobile: [{ required: true, message: '请填写运营人员手机号用“,”分割', trigger: 'blur' }],
    },
    //规则 - 手动发送短信
    rulesSendSms: {
        sendMobile: [{ required: true, message: '请填写发送手机号', trigger: 'blur' }],
        type: [{ required: true, message: '请选择预警模板', trigger: 'blur' }],
        content: [{ required: true, message: '请填写预警内容', trigger: 'blur' }],
    }
};
