
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
  import {_warningSetting} from '@/utils/pageConfig/merchant/_warningSetting';
  import {Foundation} from '@/utils/Foundation';
  import API_WarningSetting from '@/utils/API/merchant/WarningSetting';

  @Component({
    name: 'setWarningTmp',
  })
  export default class extends Vue {
    @Prop({default: () => {return []}}) tmpList: any;

    pageConfig: any = _warningSetting;
    isLoading: boolean = false;
    tmpListData: any = [];

    @Watch('tmpList', {immediate: true})
    changeTmpList(){
      let radioGroup = Foundation.findInArray(_warningSetting.setTmpForm, "value", "type");
      radioGroup.options = this.tmpList.map((e: any)=>{
        return {
          label: e.alertType,
          value: e.smsTemplateName,
        }
      })
      radioGroup.change = this.changeRadio;
    }

    async show() {
      (this.$refs.setWarningTmp as any).show();
      this.$nextTick(() => {
        this.tmpListData = Foundation.deepClone(this.tmpList);
        this.changeRadio(1);
      });
    }

    hide() {
      (this.$refs.setWarningTmp as any).hide();
    }

    changeRadio(val: number) {
      let form: any = this.$refs.setTmpForm;
      let tmp = Foundation.findInArray(this.tmpListData, "alertType", val) || {};
      this.$set(form, 'form', tmp);
      this.$set(form.form, "type", val);
      this.$set(form.form, "typeName", tmp.smsTemplateName + (tmp.smsTemplateName == '红包发放失败' ? '（只针对批量失败导致的失败提醒）' : ''));
      form.$refs.form.clearValidate();
    }

    async submitForm() {
      let form = Foundation.resetParams((this.$refs.setTmpForm as any).form);

      (this.$refs.setTmpForm as any).$refs.form.validate(async (valid: any) => {
        if (valid) {
          /*校验是否都有值*/
          for(let i = 0; i < this.tmpListData.length; i++){
            let data = this.tmpListData[i];
            if(!data.smsTemplateContent || (!data.smsSendNumDay && data.smsSendNumDay !== 0) || !data.operationMobile){
              this.$message.error(`请完善【${data.smsTemplateName}】内容`);
              return;
            }
          }

          if (this.isLoading) return;
          this.isLoading = true;
          try {
            await API_WarningSetting.updateTemplate(this.tmpListData);
            this.isLoading = false;
            this.$message.success('操作成功');
            this.hide();
            this.$emit('submitForm', form);
          } catch (e) {
            this.isLoading = false;
          }
        } else {
          return false;
        }
      });
    }
  }
