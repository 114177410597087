
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
  import {_warningSetting} from '@/utils/pageConfig/merchant/_warningSetting';
  import {Foundation} from '@/utils/Foundation';
  import API_WarningSetting from '@/utils/API/merchant/WarningSetting';

  @Component({
    name: 'sendSms',
  })
  export default class extends Vue {
    @Prop({default: () => {return []}}) tmpList: any;

    pageConfig: any = _warningSetting;
    isLoading: boolean = false;
    data: any = {};

    @Watch('tmpList', {immediate: true})
    changeTmpList(){
      let typeSelect = Foundation.findInArray(_warningSetting.sendSmsForm, "value", "alertType");
      typeSelect.options = this.tmpList.map((e: any)=>{
        return {
          value: e.alertType,
          label: e.smsTemplateName,
        }
      })
      typeSelect.change = this.changeType;
    }

    async show(data: any) {
      (this.$refs.sendSms as any).show();
      this.$nextTick(() => {
        (this.$refs.sendSmsForm as any).form = {merchantId: data.merchantId};
        (this.$refs.sendSmsForm as any).$refs.form.clearValidate();
      });
    }

    hide() {
      (this.$refs.sendSms as any).hide();
    }

    changeType(val: string){
      this.$set((this.$refs.sendSmsForm as any).form, "content", Foundation.findInArray(this.tmpList, "alertType", val).smsTemplateContent);
    }

    async submitForm() {
      let form = Foundation.resetParams((this.$refs.sendSmsForm as any).form);

      (this.$refs.sendSmsForm as any).$refs.form.validate(async (valid: any) => {
        if (valid) {
          if (this.isLoading) return;
          this.isLoading = true;
          try {
            await API_WarningSetting.sendSms(form);
            this.isLoading = false;
            this.$message.success('操作成功');
            this.hide();
            this.$emit('submitForm', form);
          } catch (e) {
            this.isLoading = false;
          }
        } else {
          return false;
        }
      });
    }
  }
