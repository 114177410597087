import Request from "../Request";

export default class WarningSetting {
    public static baseUrl = '/core/api/v1/admin/b/merchant';

    public static getWarningList(params: any, data: any) {
        return Request({
            url: `${this.baseUrl}/alert/strategy/page`,
            method: "post",
            params,
            data
        });
    }

    public static getTemplateList() {
        return Request({
            url: `${this.baseUrl}/alert/strategy/template/list`,
            method: "get"
        });
    }

    public static updateTemplate(data: any) {
        return Request({
            url: `${this.baseUrl}/alert/strategy/template/update`,
            method: "post",
            data
        });
    }

    public static getWarningItem(params: any) {
        return Request({
            url: `${this.baseUrl}/merchant/alert/list`,
            method: "get",
            params
        });
    }

    public static setWarningItem(data: any) {
        return Request({
            url: `${this.baseUrl}/set/alert/strategy`,
            method: "post",
            data
        });
    }

    public static sendSms(data: any) {
        return Request({
            url: `${this.baseUrl}/send/alert/sms`,
            method: "post",
            data
        });
    }

    public static clearStatus(data: any) {
        return Request({
            url: `${this.baseUrl}/clear/alert/status`,
            method: "post",
            data
        });
    }

    public static getRuleList(params: any) {
        return Request({
            url: `${this.baseUrl}/rule/list`,
            method: "get",
            params
        });
    }

    public static getRuleItemList(params: any) {
        return Request({
            url: `${this.baseUrl}/rule/item/list`,
            method: "get",
            params
        });
    }
}
