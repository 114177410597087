
  import {Component, Vue} from 'vue-property-decorator';
  import {_warningSetting} from '@/utils/pageConfig/merchant/_warningSetting';
  import {Foundation} from '@/utils/Foundation';
  import API_WarningSetting from '@/utils/API/merchant/WarningSetting';
  import setWarningTmp from './components/setWarningTmp.vue';
  import setWarningItem from './components/setWarningItem.vue';
  import sendSms from './components/sendSms.vue';
  import _config from "@/config/config";
  import {MyBase64} from "@/utils/MyBase64";
  import {Message, MessageBox} from "element-ui";

  @Component({
    components: {setWarningTmp, setWarningItem, sendSms},
  })
  export default class WarningSetting extends Vue {
    /*表格相关*/
    pageConfig: any = _warningSetting;
    tableData: any = [];
    total: number = 0;
    data: any = {};
    params: any = {
      current: 1,
      size: 20,
    };
    tmpList: any = [];
    strategyLink: any = {
      //奖品数量不够
      1: {
        name: "奖品数量不够",
        link: "/lottery/lotteryRule"
      },
      //红包发放失败
      2: {
        name: "红包发放失败",
        link: "/setting/redPacketV3Record"
      },
      //系统余额不足
      3: {
        name: "系统余额不足",
        link: "/dashboard/index?tabIndex=3"
      }
    };

    mounted() {
      this.getTmpList();
      this.getTableData();
    }

    /*获取表格数据*/
    async getTableData() {
      let res: any = await API_WarningSetting.getWarningList(this.params, this.data);
      this.tableData = (res.records && res.records.length > 0 &&
          res.records.map((e: any) => {
            e._flagNum = e.alertStrategyItemList.filter((s: any)=>{return s.status}).length;
            return e
          })) || [];
      this.total = res.total;
    }

    /*底部分页切换*/
    currentChange(pageNumber: number) {
      this.params.current = pageNumber;
      this.getTableData();
    }

    /*底部分页条数切换*/
    sizeChange(sizeNumber: number) {
      this.params.size = sizeNumber;
      this.getTableData();
    }

    /*查询*/
    handleSearch() {
      let query: any = Foundation.resetParams((this.$refs.searchForm as any).form, true);
      this.params = {
        current: 1,
        size: 20,
      };
      this.data = query;
      this.getTableData();
    }

    /*获取模板列表*/
    async getTmpList(){
      this.tmpList = await API_WarningSetting.getTemplateList();

      let typeSelect = Foundation.findInArray(_warningSetting.search, "value", "alertTypeList");
      typeSelect.options = this.tmpList.map((e: any)=>{
        return {
          value: e.alertType,
          label: e.smsTemplateName + "：是",
        }
      })
    }

    /*预警模板设置*/
    handleTemplate() {
      (this.$refs.setWarningTmp as any).show();
    }

    /*设置预警项*/
    handleItem(data: any) {
      (this.$refs.setWarningItem as any).show(data);
    }

    /*手动发送短信*/
    handleSend(data: any) {
      (this.$refs.sendSms as any).show(data);
    }

    /*点击预警条件*/
    handleAlert(row: any, item: any){
      let link = this.strategyLink[item.alertType].link;
      if(item.status){
        MessageBox.confirm(`是否要处理[${this.strategyLink[item.alertType].name}]预警`, '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '立即处理',
          cancelButtonText: '已处理',
          type: 'warning'
        }).then(() => {
          this.goMerchant(row, link);
        }).catch(async (action) => {
          if(action === "cancel"){
            await API_WarningSetting.clearStatus({id: item.id});
            this.$message.success('操作成功');
            this.getTableData();
          }
        });
      }else{
        this.goMerchant(row, link);
      }
    }

    /*跳转商户系统*/
    async goMerchant(row: any, path: string) {
      const params: any = {};
      params[`coCode`] = row.merchantCode;
      params[`token`] = localStorage.getItem('qymanagementtoken');

      window.open(`${_config.MERCHANT_URL}#${path}${path.indexOf('?')>-1 ? '&' : '?'}params=${encodeURIComponent(MyBase64.Encode(JSON.stringify(params)))}`);
    }
  }
