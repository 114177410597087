
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
  import {_warningSetting} from '@/utils/pageConfig/merchant/_warningSetting';
  import {Foundation} from '@/utils/Foundation';
  import setWarningPrize from './setWarningPrize.vue';
  import API_WarningSetting from "@/utils/API/merchant/WarningSetting";

  @Component({
    components: {setWarningPrize},
  })
  export default class extends Vue {
    @Prop({default: () => {return []}}) tmpList: any;

    pageConfig: any = _warningSetting;
    data: any = {};
    ruleList: any = [];
    itemList: any = {}; //预警项列表数据
    //默认值
    defaultStrategy: any = {
      //奖品数量不够
      1: {
        rule: [],
        prizeData: {}
      },
      //红包发放失败
      2: {
        nqSendStatus: 'SUCCESS'
      },
      //系统余额不足
      3: {
        amount: ''
      }
    };
    isLoading: boolean = false;

    /*初始化设置预警项默认数据*/
    @Watch('tmpList', {immediate: true})
    setDefaultItemList(){
      this.itemList = {};
      this.tmpList.map((e: any)=>{
        this.$set(this.itemList, e.alertType, {
          isCheck: false,
          strategyJson: Foundation.deepClone(this.defaultStrategy[e.alertType]) || {},
          customMobile: ''
        });
      })
    }

    show(data: any = {}) {
      this.data = Foundation.deepClone(data);
      this.getRuleList();
      this.setDefaultItemList();
      this.getItemData();
      (this.$refs.setWarningItem as any).show();
    }

    hide() {
      (this.$refs.setWarningItem as any).hide();
    }

    /*获取预警项数据*/
    async getItemData(){
      let res: any = await API_WarningSetting.getWarningItem({merchantId: this.data.merchantId});
      this.toBefore(res);
    }

    /*转化为前端所需数据结构*/
    toBefore(data: any){
      data.map((e: any)=>{
        let item = this.itemList[e.alertType];
        if(item){
          let strategyJson = JSON.parse(e.strategy);
          item.isCheck = true;
          item.customMobile = e.customMobile;
          item.status = e.status;

          if(e.alertType == 1){
            //处理【奖品数量不够】中的规则项配置数据
            strategyJson.map((prize: any)=>{
              if(item.strategyJson.prizeData[prize.ruleId]){
                item.strategyJson.prizeData[prize.ruleId].push(prize);
              }else{
                item.strategyJson.prizeData[prize.ruleId] = [prize];
              }
            });
            item.strategyJson.rule = Object.keys(item.strategyJson.prizeData).map((r:  any)=>{return parseInt(r)});
          }else{
            item.strategyJson = strategyJson;
            e.alertType == 3 && (item.strategyJson.amount = Foundation.toYUAN(item.strategyJson.amount));
          }
        }
      });
    }

    /*获取商户下所有规则*/
    async getRuleList() {
      let res: any = await API_WarningSetting.getRuleList({merchantId: this.data.merchantId});
      this.ruleList = res.map((e: any)=>{
        return {
          value: e.id,
          label: e.name
        };
      })
    }

    /*获取规则名称*/
    getRuleText(ruleId: string){
      return Foundation.findInArray(this.ruleList, "value", ruleId)?.label || "";
    }

    /*设置奖品阈值*/
    handleSetPrize(ruleId: string){
      (this.$refs.setWarningPrize as any).show({ruleId , merchantId: this.data.merchantId}, this.itemList[1].strategyJson.prizeData[ruleId]);
    }

    /*奖品阈值回调*/
    submitPrizeItem(prizeData: any, ruleId: string){
      this.$set(this.itemList[1].strategyJson.prizeData, ruleId, prizeData);
    }

    /*删除规则时移除规则项配置*/
    removeTag(ruleId: any){
      delete this.itemList[1].strategyJson.prizeData[ruleId];
    }

    /*转化为后端所需数据结构*/
    toAfter(){
      let data = [], key: any;
      for(key in this.itemList){
        let val = Foundation.deepClone(this.itemList[key]);
        if(val.isCheck){
          let strategy: any = val.strategyJson;

          if(key == 1){
            //处理【奖品数量不够】中的规则项配置数据
            strategy = [];
            for(let ruleId in val.strategyJson.prizeData){
              let prizes = val.strategyJson.prizeData[ruleId].filter((e: any)=>{return e.alertNumber !== ""});
              prizes.map((e: any)=>{
                strategy.push({
                  ruleItemId: e.id || e.ruleItemId,
                  ruleId: e.ruleId,
                  alertNumber: e.alertNumber,
                });
              })
            }
          }else if(key == 3){
            //处理【系统余额不足】中的金额
            strategy.amount = Foundation.toMoney(strategy.amount)
          }

          data.push({
            merchantId: this.data.merchantId,
            alertType: key,
            status: val.status || 0,
            strategy: JSON.stringify(strategy),
            customMobile: val.customMobile
          });
        }
      }
      return data;
    }

    /*表单提交*/
    async submitForm() {
      let data = this.toAfter();

      if (this.isLoading) return;
      this.isLoading = true;
      try {
        console.log(JSON.stringify(data));
        await API_WarningSetting.setWarningItem(data);
        this.isLoading = false;
        this.$message.success('操作成功');
        this.hide();
        this.$emit('submitForm', data);
      } catch (e) {
        this.isLoading = false;
      }
    }
  }
