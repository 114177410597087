
import {Component, Vue} from 'vue-property-decorator';
import {_warningSetting} from '@/utils/pageConfig/merchant/_warningSetting';
import API_WarningSetting from "@/utils/API/merchant/WarningSetting";
import {Foundation} from "@/utils/Foundation";

@Component({
  name: 'setWarningPrize',
  components: {
  },
})
export default class setWarningPrize extends Vue {
  pageConfig: any = _warningSetting;
  isLoading: boolean = false;
  params: any = {};
  data: any = [];
  tableData: any = [];
  props: any = {
    'row-key': 'id'
  }

  show(params: any, data:any) {
    this.params = params;
    this.data = data || [];
    (this.$refs.setWarningPrize as any).show();
    if(!data || data.length == 0 || !data[0]?.id){
      this.getTableData();
    }else{
      this.tableData = data;
    }
  }

  hide() {
    (this.$refs.setWarningPrize as any).hide();
  }

  /*获取表格数据*/
  async getTableData() {
    let res: any = await API_WarningSetting.getRuleItemList(this.params);
    this.tableData = (res && res.length > 0 &&
        res.map((e: any) => {
          return this.dealRow(e);
        })
    ) || [];
  }

  dealRow(e: any) {
    e.alertNumber = Foundation.findInArray(this.data, "ruleItemId", e.id)?.alertNumber || "";
    e.residueNumber = e.prizeNumber - e.useNumber;
    if (e.subRuleItem && e.subRuleItem.length > 0) {
      let child = [];
      for (let item of e.subRuleItem) {
        child.push({...this.dealRow(item), _parent: e.id});
      }
      e.children = child;
    }
    return e;
  }

  submitForm(){
    this.hide();
    this.$emit("submitPrizeItem", this.tableData, this.params.ruleId);
  }
}
